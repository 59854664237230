import Cookies from 'js-cookie'
import store from '@/store'

const TokenKey = 'isLogged'
const Token = 'token'
const User = 'user'

// Function to set cookies in subdomain
function setCookieInSubdomain(cookieName, value) {
  const domainParts = window.location.hostname.split('.')
  let domain
  if (domainParts.length > 3) {
    domain = domainParts.slice(-3).join('.')
  } else if (domainParts.length <= 2 || window.location.hostname === 'localhost') {
    domain = window.location.hostname
  } else {
    domain = domainParts.slice(-2).join('.')
  }
  return Cookies.set(cookieName, value, { domain })
}

// Function to get cookies in subdomain
function getCookieInSubdomain(cookieName) {
  const domainParts = window.location.hostname.split('.')
  let domain
  if (domainParts.length > 3) {
    domain = domainParts.slice(-3).join('.')
  } else if (domainParts.length <= 2 || window.location.hostname === 'localhost') {
    domain = window.location.hostname
  } else {
    domain = domainParts.slice(-2).join('.')
  } return Cookies.get(cookieName, { domain })
}

// Function to remove cookies in subdomain
function removeCookieInSubdomain(cookieName) {
  const domainParts = window.location.hostname.split('.')
  let domain
  if (domainParts.length > 3) {
    domain = domainParts.slice(-3).join('.')
  } else if (domainParts.length <= 2 || window.location.hostname === 'localhost') {
    domain = window.location.hostname
  } else {
    domain = domainParts.slice(-2).join('.')
  } return Cookies.remove(cookieName, { domain })
}

export function getLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition)
  } else {
  }
}

function showPosition(position) {
  const Latitude = position.coords.latitude
  const Longitude = position.coords.longitude
}

export function isLogged() {
  return getCookieInSubdomain(TokenKey) === '1'
}

export function notHasPermissions() {
  return getCookieInSubdomain(TokenKey) === '2'
}

export function setLogged(isLogged) {
  return setCookieInSubdomain(TokenKey, isLogged)
}

export function removeToken() {
  return removeCookieInSubdomain(TokenKey)
}

export function setToken(token) {
  return setCookieInSubdomain(Token, token)
}

export function getToken() {
  return getCookieInSubdomain(Token)
}

export function deleteToken() {
  return removeCookieInSubdomain(Token)
}

export function Auth(user) {
  return setCookieInSubdomain(User, JSON.stringify(user))
}

export function getAuth() {
  return getCookieInSubdomain(User)
}

export function removeAuth() {
  return removeCookieInSubdomain(User)
}

export function resetPermission() {
  store.commit('roles/SET_AUTH_USER_PERMISSIONS', [])
  store.commit('roles/SET_AUTH_USER_ROLES_PERMISSIONS', [])
}
